import { isPlatformBrowser } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { variables } from 'libs/shared/utils/variables/variables';

export function resolveTranslate() {
  const translateService = inject(TranslateService);
  const platformId = inject(PLATFORM_ID);

  let storedLang = undefined;
  if (isPlatformBrowser(platformId)) {
    storedLang = localStorage?.getItem(variables.languageCodeStorageKey);
  }

  const currentLang = translateService.currentLang || storedLang || 'en';
  translateService.currentLang = '';
  translateService.use(currentLang);
}
