import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function loadTranslate(path: string) {
  return TranslateModule.forChild({
    extend: true,
    isolate: false,

    loader: {
      provide: TranslateLoader,
      useFactory: (http: HttpClient) => new TranslateHttpLoader(http, path, '.json'),
      deps: [HttpClient],
    },
  });
}
